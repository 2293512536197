import React from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { BASE_URL } from "../Constants";
import HomePageComponent from "./HomePageComponent";
import FooterComponent from "./FooterComponent";

const fetchAllSubcategories = async () => {
  const response = await axios.get(`${BASE_URL}/api/subcategory/getAll`);
  return response.data;
};

function GetAllContent() {
  const { data, isLoading, isError } = useQuery(
    "allSubcategories",
    fetchAllSubcategories,
    {
      staleTime: 60000, // Adjust as needed
      cacheTime: 3600000, // Adjust as needed
    }
  );
  document.title = "Home | Gangadwar.in | Gangadwar Bhajnawali";
  return (
    <>
      {isLoading ? (
        <div className="container">
          <h4 className="loading">Loading...</h4>
        </div>
      ) : isError ? (
        <p>Error: Something went wrong!</p>
      ) : (
        <>
          
          <HomePageComponent data={data} />
          <FooterComponent />
        </>
      )}
    </>
  );
}

export default GetAllContent;

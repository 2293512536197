import React from "react";
import { isMobileApp } from "../Constants";

const FooterComponent = () => {
  const currentYear = new Date().getFullYear(); // Get the current year

  return (<footer className="footer">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <hr />
          <div className="text-center sub-color">
            {!isMobileApp && (
              <a
                href="https://play.google.com/store/apps/details?id=com.sarvesh.gangadwar"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Download Gangadwar App from Google Play"
                
              >
                <strong className="link-color">Download App on Google Play!</strong>
              </a>
            )}
            <p>&copy; {currentYear} Gangadwar Bhajnawali. All rights reserved.</p>
            <p>
              <a
                href="/page/about-us"
                aria-label="Learn more about Gangadwar on the About Us page"
              >
                Designed and Developed by <strong>Biyani</strong> Family
              </a>
            </p>
            <p>
              <a
                href="/feedback"
                aria-label="Provide your feedback on Gangadwar"
              >
                <b className="link-color"> Provide Feedback here</b>
              </a>
            </p>
            <address>
              For support, contact <a  href="mailto:biyani@gangadwar.in"> <b >biyani@gangadwar.in</b> </a>
            </address>
          </div>
        </div>
      </div>
    </div>
  </footer>  
  );
};

export default FooterComponent;
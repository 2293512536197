

export const TEMP_DEMO = '';
// export const TEMP_DEMO ='/demo';

// Web
export const BASE_URL = '';
export const isMobileApp = false;

// Android
// export const isMobileApp = true;
// export const BASE_URL = 'https://android.gangadwar.in';
// export const BASE_URL = 'https://dev-android.gangadwar.in';


// export const BASE_URL = 'http://localhost:8080';
// export const BASE_URL = 'http://192.168.1.20:8080';
// export const BASE_URL = 'https://gangadwar.in';

export const ONESIGNAL_APP_ID  = "801df9f7-cb59-471e-b869-479476c09e8f";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/js/bootstrap.js";
import React, { useState, useEffect } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { BASE_URL, TEMP_DEMO } from "../Constants";
import "./css/Nav.css";
import logo from "./images/gangadwar_web_logo.webp";
import NavbarSearch from "./NavBarSearch";

const fetchSubcategories = async () => {
  const response = await axios.get(`${BASE_URL}/api/subcategory/getNav`);
  return response.data;
};

const NavBarComponent = () => {
  const {
    data: subcategories = [],
    isLoading,
    isError,
  } = useQuery("subcategories", fetchSubcategories);

  const [expanded, setExpanded] = useState(false); // For controlling navbar collapse

  useEffect(() => {
    // Handle loading or error states if needed
  }, [isLoading, isError]);

  const handleSearchComplete = () => {
    setExpanded(false); // Collapse the navbar when search is completed
  };

  return (
    <Navbar expand="lg" collapseOnSelect expanded={expanded}>
      <Navbar.Brand className="d-flex align-items-center">
        <Link
          to={`${TEMP_DEMO}/`}
          className="d-flex align-items-center"
          onClick={() => setExpanded(false)}
        >
          <img src={logo} alt="Gangadwar" className="navlogo" />
        </Link>
        <Link
          to={`${TEMP_DEMO}/`}
          className="navbar-brand ms-2"
          onClick={() => setExpanded(false)}
        >
          Gangadwar Bhajnawali
        </Link>
      </Navbar.Brand>

      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        onClick={() => setExpanded(!expanded)}
      />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav>
        <Link
  className="nav-link"
  to={`${TEMP_DEMO}/`}
  onClick={() => setExpanded(false)}
>
  Home / होम
</Link>

<Link
  className="nav-link"
  to="/page/about-us"
  onClick={() => setExpanded(false)}
>
  About / हमारे बारे में
</Link>

<Link
  className="nav-link"
  to="/feedback"
  onClick={() => setExpanded(false)}
>
  Feedback / सुझाव
</Link>

<NavDropdown title="Bhajans / भजन" id="basic-nav-dropdown">
  {subcategories.map((subcategory) => (
    <Link
      className="dropdown-item"
      key={subcategory.subCategoryId}
      to={`${TEMP_DEMO}/subcategory/${subcategory.subCategoryId}`}
      onClick={() => setExpanded(false)} // Collapse on click
    >
      {subcategory.titlePrimary}
    </Link>
  ))}
</NavDropdown>

        </Nav>
      </Navbar.Collapse>

      {/* Pass the handleSearchComplete function to NavbarSearch to collapse the navbar on search */}
      <NavbarSearch onSearchComplete={handleSearchComplete} />
    </Navbar>
  );
};

export default NavBarComponent;

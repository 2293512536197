import React, { useState, useEffect } from "react";
import { Form, FormControl, Button, Spinner } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { FaSearch } from "react-icons/fa";

const NavbarSearch = ({ onSearchComplete }) => {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Set initial query from URL if it exists, clear it when not on search page
  useEffect(() => {
    const urlQuery = new URLSearchParams(location.search).get("query");
    if (location.pathname === "/search" && urlQuery) {
      setQuery(urlQuery);
    } else if (location.pathname !== "/search") {
      setQuery(""); // Clear the search bar when navigating to a non-search page
    }
  }, [location]);

  const handleSearch = async (term) => {
    if (term.trim()) {
      setLoading(true); // Show loading spinner
      navigate(`/search?query=${encodeURIComponent(term)}`);
      setLoading(false); // Hide loading spinner after navigation
      if (onSearchComplete) {
        onSearchComplete(); // Collapse the navbar if provided
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(query);
    }
  };

  return (
    <Form
      className="d-flex justify-content-center responsive-search-bar"
      onSubmit={(e) => {
        e.preventDefault();
        handleSearch(query);
      }}
    >
      <FormControl
        type="search"
        placeholder="&#128269;"
        className="me-2 search-box pt-2"
        aria-label="Search"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyUp={handleKeyPress}
      />
      <Button
        variant="custom"
        className="btn btn-primary p-2"
        onClick={() => handleSearch(query)}
        disabled={loading}
      >
        

        {loading ? <Spinner as="span" animation="border" size="sm" /> : "Search"}
      </Button>
    </Form>
  );
};

export default NavbarSearch;

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BASE_URL, TEMP_DEMO } from "../Constants";
import FooterComponent from "./FooterComponent";

const SearchPage = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [initialTerm, setInitialTerm] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state
  const location = useLocation();
  const query = new URLSearchParams(location.search).get("query");

  useEffect(() => {
    if (query) {
      setInitialTerm(query);
      fetchSearchResults(query);
    }
  }, [query]);

  const fetchSearchResults = async (term) => {
    try {
      setLoading(true); // Set loading to true before API call
      const response = await fetch(
        BASE_URL + `/api/content/search?term=${term}`
      );
      if (response.ok) {
        const data = await response.json();
        setSearchResults(data.response.docs);
      } else {
        console.error("Failed to fetch data from the API");
      }
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after API call is done
    }
  };

  return (
    <>
      <div className="container py-4 py-xl-5 max-height">
        <div className="col-md-12 col-xl-8 text-center mx-auto text-center mb-3 search-title">
          {initialTerm && (
            <>
              Showing results for <br />
              <span className="">{initialTerm}</span>
            </>
          )}
        </div>
        <hr />
        <div className="row gy-4 row-cols-1 p-2">
          {loading ? ( // Show loading state while fetching data
            <div className="text-center loading">Loading...</div>
          ) : Array.isArray(searchResults) && searchResults.length > 0 ? (
            searchResults.map((item) => (
              <h4 style={{ textAlign: "center" }} key={item.contentId}>
                <a
                  className="content-link"
                  href={`${TEMP_DEMO}/content/${item.contentId}`}
                >
                  {item.titlePrimary}
                </a>
              </h4>
            ))
          ) : (
            <div className="text-center">No results found</div>
          )}
        </div>
      </div>
      <FooterComponent />
    </>
  );
};

export default SearchPage;

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MdOutlineFeedback } from "react-icons/md";
import { BASE_URL } from "../Constants";

const FeedbackForm = () => {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isFloatingButtonVisible, setIsFloatingButtonVisible] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    feedbackText: "",
    rating: 0,
    deviceInfo: navigator.userAgent,
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Show overlay when navigating to /feedback
    if (location.pathname === "/feedback") {
      setIsOverlayVisible(true);
    } else {
      setIsOverlayVisible(false);
    }

    const handleScroll = () => {
      const scrolledToBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight;
      setIsFloatingButtonVisible(!scrolledToBottom);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]); // Re-run effect when route changes

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleStarClick = (ratingValue) => {
    setFormData((prevData) => ({
      ...prevData,
      rating: ratingValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage("");
    setErrorMessage("");
    try {
      const queryParams = new URLSearchParams(formData).toString();
      const response = await fetch(
        `${BASE_URL}/public/api/feedback/create?${queryParams}`,
        {
          method: "GET", // GET method with query parameters
        }
      );
      if (response.ok) {
        setSuccessMessage("Thank you for your feedback!");
        setFormData({
          name: "",
          email: "",
          feedbackText: "",
          rating: 0,
        });
        navigate(-1); // Go back to the previous page
      } else {
        const error = await response.json();
        setErrorMessage(error.message || "Failed to submit feedback.");
      }
    } catch (error) {
      setErrorMessage("Something went wrong. Please try again later.");
    }
  };

  const closeOverlay = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <>
      {/* Floating Button */}
      {isFloatingButtonVisible && (
        <button
          className="floating-button"
          onClick={() => navigate("/feedback")}
        >
          <MdOutlineFeedback />
        </button>
      )}

      {/* Feedback Form Overlay */}
      {isOverlayVisible && (
        <div className="feedback-overlay">
          <div className="feedback-form-container">
            <button className="close-button" onClick={closeOverlay}>
              &times;
            </button>
            <h2>Feedback Form</h2>
            <form onSubmit={handleSubmit} className="feedback-form">
              <div>
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label htmlFor="feedbackText">Feedback:</label>
                <textarea
                  id="feedbackText"
                  name="feedbackText"
                  value={formData.feedbackText}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <div className="rating-container">
                <label>Rating:</label>
                <div className="stars">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      className={`star ${
                        formData.rating >= star ? "selected" : ""
                      }`}
                      onClick={() => handleStarClick(star)}
                    >
                      ★
                    </span>
                  ))}
                </div>
              </div>
              <button type="submit">Submit</button>
            </form>
            {successMessage && (
              <p className="success-message">{successMessage}</p>
            )}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        </div>
      )}
    </>
  );
};

export default FeedbackForm;
